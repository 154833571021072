// import $ from 'jquery';
import UIkit from 'uikit/dist/js/uikit.min'; // eslint-disable-line no-unused-vars
// import './vendors/uikit-icons';
import './vendors/lazyload';
// --------------
// variables
// --------------
const hello = 'Hello world!';
// --------------
// functions
// --------------
console.log(hello);
// --------------
// event listeners & starters
// --------------
